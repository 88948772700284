@use '@angular/material' as mat;
@import '@ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import '@ag-grid-community/styles/ag-theme-quartz.css';

@include mat.core();

$primary-palette: (
  50 : #e0e7f2,
  100 : #b3c4e0,
  200 : #809dcb,
  300 : #4d76b6,
  400 : #2658a6,
  500 : #003b96,
  600 : #00358e,
  700 : #002d83,
  800 : #002679,
  900 : #001968,
  A100 : #97a7ff,
  A200 : #647bff,
  A400 : #3150ff,
  A700 : #183aff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$accent-palette: (
  50 : #edf4ff,
  100 : #d1e3ff,
  200 : #b3d1ff,
  300 : #94beff,
  400 : #7db0ff,
  500 : #66a2ff,
  600 : #5e9aff,
  700 : #5390ff,
  800 : #4986ff,
  900 : #3875ff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #e1eaff,
  A700 : #c8d8ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$custom-primary-dark: (
  50 : #e7efe4,
  100 : #c3d6bb,
  200 : #9cbb8e,
  300 : #749f61,
  400 : #568b3f,
  500 : #38761d,
  600 : #326e1a,
  700 : #2b6315,
  800 : #245911,
  900 : #17460a,
  A100 : #94ff7d,
  A200 : #6aff4a,
  A400 : #40ff17,
  A700 : #2dfc00,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$custom-accent-dark: (
  50 : #e7efe4,
  100 : #c3d6bb,
  200 : #9cbb8e,
  300 : #749f61,
  400 : #568b3f,
  500 : #38761d,
  600 : #326e1a,
  700 : #2b6315,
  800 : #245911,
  900 : #17460a,
  A100 : #94ff7d,
  A200 : #6aff4a,
  A400 : #40ff17,
  A700 : #2dfc00,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$ag-primary: mat.define-palette($primary-palette);
$ag-accent: mat.define-palette($accent-palette);

$ag-primary-dark: mat.define-palette($custom-primary-dark);
$ag-accent-dark: mat.define-palette($custom-accent-dark);
$font-family: "Arial", sans-serif;

$ag-light-theme: mat.define-light-theme((
  color: (
    primary: $ag-primary,
    accent: $ag-accent,
  ),
  typography: mat.define-typography-config($font-family),
  density: 0,
));

$ag-dark-theme: mat.define-dark-theme((
  color: (
    primary: $ag-primary-dark,
    accent: $ag-accent-dark,
  ),
  typography: mat.define-typography-config($font-family),
  density: 0,
));

// using ~ *:not(script) to apply style changes for overlay containers (datepickers, modals, etc)
.light-theme, .light-theme ~ *:not(script) {
  @include mat.all-component-colors($ag-light-theme);
  @include mat.all-component-typographies($ag-light-theme);
  @include mat.all-component-densities($ag-light-theme);
}

.dark-theme, .dark-theme ~ *:not(script) {
  @include mat.all-component-colors($ag-dark-theme);
  @include mat.all-component-typographies($ag-dark-theme);
  @include mat.all-component-densities($ag-dark-theme);
}
