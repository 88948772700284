/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@import "theme";

$marginSmall: 8px;
$marginMedium: 20px;
$marginLarge: 30px;

$paddingSmall: 8px;
$paddingMedium: 20px;
$paddingLarge: 30px;

.ag-theme-quartz {
  --ag-font-family: Arial, sans-serif;
  --ag-grid-size: 6px;
  --ag-row-height: 50px;
  --ag-list-item-height: 20px;
}


html, body, app-root {
  height: 100vh;
  min-width: 800px;
  font-family: Arial, sans-serif;
}

body {
  margin: 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
  overflow: hidden; /* Prevents the container from causing page scrolling */
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 200;
  flex-shrink: 0;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1; /* Allows it to grow within the available space of main-container */
  min-height: 0; /* Critical to prevent growing outside its parent */
  overflow: auto; /* Allows scrolling within this container if content overflows */
}

.footer-container {
  bottom: 0;
  width: 100%;
  flex-basis: 40px;
  flex-shrink: 0;
}

.full-height-container {
  display: flex !important;
  flex-direction: column !important;
  flex: 1 !important; /* Allows it to grow within the available space of main-container */
  min-height: 0 !important; /* Critical to prevent growing outside its parent */
  overflow: auto !important; /* Allows scrolling within this container if content overflows */
}

.form-container {
  height: 0;
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.container {
  max-width: 1200px;
  margin: 8px auto auto;
}

.page-sub-header {
  font-size: 1.5em;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-centered {
  align-items: center;
  justify-content: center;
}

.right-align {
  margin-left: auto;
}

.left-align {
  margin-right: auto;
}

.baseline-align {
  align-items: baseline;
}

.float-left {
  float: left;
}

.flex-g-s {
  gap: $marginSmall;
}

.flex-g-m {
  gap: $marginMedium;
}

.flex-g-l {
  gap: $marginLarge;
}

//spacing, margin/ padding settings
.mg-s {
  margin: $marginSmall;
}

.mg-m {
  margin: $marginMedium;
}

.mg-l {
  margin: $marginLarge;
}

.mg-r-s {
  margin-right: $marginSmall;
}

.mg-r-m {
  margin-right: $marginMedium;
}

.mg-r-l {
  margin-right: $marginLarge;
}

.mg-l-s {
  margin-left: $marginSmall;
}

.mg-l-m {
  margin-left: $marginMedium;
}

.mg-l-l {
  margin-left: $marginLarge;
}

.mg-t-s {
  margin-top: $marginSmall;
}

.mg-t-m {
  margin-top: $marginMedium;
}

.mg-t-l {
  margin-top: $marginLarge;
}

.mg-b-s {
  margin-bottom: $marginSmall;
}

.mg-b-m {
  margin-bottom: $marginMedium;
}

.mg-b-l {
  margin-bottom: $marginLarge;
}

.pd-s {
  padding: $paddingSmall;
}

.pd-m {
  padding: $paddingMedium;
}

.pd-l {
  padding: $paddingLarge;
}

.pd-r-s {
  padding-right: $paddingSmall;
}

.pd-r-m {
  padding-right: $paddingMedium;
}

.pd-r-l {
  padding-right: $paddingLarge;
}

.pd-l-s {
  padding-left: $paddingSmall;
}

.pd-l-m {
  padding-left: $paddingMedium;
}

.pd-l-l {
  padding-left: $paddingLarge;
}

.pd-t-s {
  padding-top: $paddingSmall;
}

.pd-t-m {
  padding-top: $paddingMedium;
}

.pd-t-l {
  padding-top: $paddingLarge;
}

.pd-b-s {
  padding-bottom: $paddingSmall;
}

.pd-b-m {
  padding-bottom: $paddingMedium;
}

.pd-b-l {
  padding-bottom: $paddingLarge;
}

.primary-font-color {
  color: #003B96;
}

.primary-link-color {
  text-decoration: none;
  color: #0064FF;
}

.white-font-color {
  color: #FFFFFF !important;
}

//font settings
.font-bold {
  font-weight: bold;
}

// table settings
.mat-mdc-header-row.mdc-data-table__header-row.cdk-header-row {
  background-color: #f5f5f5;
}

.dual-line-table-column {
  height: 1em;
}

.multi-line-table-label {
  height: 2em;
}

.multi-line-table-link {
  width: 100%;
  margin: 5px 0;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-mdc-row:nth-child(even) {
  background-color: #FAFAFA;
}

.mat-mdc-row:nth-child(odd) {
  background-color: #FFFFFF00;
}

// Chip set settings
.mat-mdc-chip-set, .mat-mdc-chip {
  max-width: 150px;
  min-width: 150px;
}

// Form field settings
.mat-mdc-form-field {
  min-width: 100px !important;
}

.mat-mdc-form-field-icon-suffix {
  display: contents !important;
}

// Button
button, .mat-button {
  border-radius: 2px !important;
}

.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  background-color: transparent;
  color: #003B96;
  font-size: 12px;
  height: 30px;
  border: .5px solid #003B96;
  padding: 0 30px;
  cursor: pointer;

  &:hover {
    background-color: #003B961A;
  }
}

.secondary-button i {
  margin-right: 10px;
}


.failed-rows-download-button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  background-color: transparent;
  color: #003B96;
  font-size: 12px;
  border: .5px solid #003B96;
  padding: 0 30px;
  cursor: pointer;

  &:hover {
    background-color: #003B961A;
  }
}

.button-span-margin-left {
  margin-left: 5px
}

.primary-alt-button {
  background-color: #66A2FF !important;
  color: #FFFFFF !important;
}

button[disabled='true'], a[disabled='true'] {
  background-color: #E9E9E9 !important;
}

mat-chip-option, mat-chip, mat-form-field > .mdc-text-field {
  border-radius: 0 !important;
  background-color: white;
}

mat-datepicker-toggle {
  align-self: center;
}

.mdc-notched-outline__leading {
  border-radius: 0 !important;
}

.mdc-notched-outline__trailing {
  border-radius: 0 !important;
}

// Divider
.mat-divider {
  border-color: black;
}

// Content
.hide-content {
  content-visibility: hidden;
  visibility: hidden;
  max-height: 1rem;
}

.disable_icon {
  pointer-events: none;
  opacity: 0.3;
  cursor: not-allowed;
}

//overflow
.hide-overflow-x {
  overflow-x: hidden;
}

// width controls
.full-width {
  width: 100%;
}

.full-width > * {
  width: 100%;
}

.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

//Cursor
.point-cursor {
  cursor: pointer;
}

.primary-color {
  color: #003B96;
}

.error-color {
  color: #CB0000;
}

.error-border-solid {
  border: 2px solid #CB0000;
}

.action-icon {
  color: #66A2FF;
}

.grey-color {
  color: #d1d1d1;
}

.green-color {
  color: green;
}

.light-green-color {
  color: lightgreen;
}

.yellow-color {
  color: yellow;
}

.slide-toggle {
  margin-right: 10px;
}

// ag-side-panel custom styles
.mat-drawer-inner-container {
  background-color: #f5f5f5;
}

// mat-sidenav custom styles
.mat-drawer.mat-sidenav {
  top: 0;
  bottom: 0;
}

// mat-paginator custom styles
.mat-mdc-form-field.mat-mdc-paginator-page-size-select {
  width: 100px;
}

// ag-grid-angular custom styles

.ag-center-cols-container .ag-cell,
.ag-pinned-left-cols-container .ag-cell,
.ag-pinned-right-cols-container .ag-cell {
  display: flex;
  align-items: center;
}

.ag-header {
  background-color: #F5F5F5;
}

.ag-row-even {
  background-color: #FFFFFF00;
}

.ag-row-odd {
  background-color: #F5F5F580;
}

.ag-checkbox-input-wrapper.ag-checked::after,
.ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: #003B96;
}

.ag-cell-wrapper {
  overflow: hidden;
}

.cdk-overlay-pane {
  background-color: #F5F5F5;

  .mat-calendar {
    margin: 0 auto;
  }
}

// moved globally so I don't need to ng-deep in my component.
.ag-checkbox-list-autocomplete mat-option mat-pseudo-checkbox {
  display: none;
}

.ag-search-dropdown-autocomplete mat-option mat-pseudo-checkbox {
  display: none;
}

.light-theme {
  // using id's to override third-party styles with their specificity. Each id represents component
  #header {

    .sub-menu-bar {
      background-color: #1A2373;
    }

    .sub-menu-item-selected .mat-mdc-button-touch-target {
      background-color: #003B96;
    }
  }

  #progress-bar {
    background-color: rgba(232, 232, 231, 0.7);
  }

  #sidenav-support {
    background-color: #f2f2f2;
  }

  #side-panel {
    .card-content {
      background-color: #F5F5F5;
    }
  }

  #task-component {
    .task-component-detail {
      height: 100%;
      background-color: #FAFAFA;
    }
  }

  #task-component-bulk {
    .table-title {
      color: #0000008A;
    }
  }

  #task-component-detail {
    .mat-mdc-text-field-wrapper {
      background-color: #FFFFFF;
      border: 1px solid #979494;
      border-bottom: 1px solid transparent;
    }

    .mdc-text-field--disabled {
      border-bottom: 1px solid #979494;
    }

    .url-link {
      color: #646464;
      background-color: transparent;
    }

    .url-link-icon {
      color: #66A2FF;
    }
  }

  #product-assignment {

    .selected-sub-menu {
      background-color: lightgray;
    }
  }

  #transfer-run-filters {
    .slide-toggle-wrapper {
      color: #7E7E7E;
    }
  }

  #transfer-run-detail {

    .table_control_panel {
      background-color: white;
    }
  }

  #auto-refresh-wrapper {
    .display-color-enabled {
      color: #003B96;
    }

    .display-color-disabled {
      color: #7E7E7E;
    }

    .auto-refresh {
      color: #7E7E7E;
    }

    .fa-gear-complex {
      color: #979494;
    }

    .mat-mdc-menu-item {
      color: #003B96 !important;
    }
  }

  #datablock {
    .title {
      color: #000;
    }

    .data {
      color: #646464;
    }
  }

  #expansion-panel-wrapper {
    .expansion-panel {
      background-color: #E9E9E9;
    }
  }

  #file-upload {
    &.fileOver {
      border-color: darkblue;
      background-color: #759edc;
    }
  }

  #kpi-sidebar {
    background-color: #FAFAFA;
    border: 1px solid #646464;

    .sidebar-container {

      .sidebar-item {
        color: #FFFFFF;
        background-color: #003B96E5;
      }
    }
  }

  #skeleton {
    @keyframes skeleton-loading {
      0% {
        background-color: hsl(222, 13%, 85%);
      }
      100% {
        background-color: hsl(200, 20%, 95%);
      }
    }
  }

  #slide-toggle-wrapper {

    .slide-toggle-wrapper {
      border: 2px solid #7E7E7E;

      ::ng-deep .mdc-switch__track::before, ::ng-deep .mdc-switch__track::after {
        background: transparent;
      }

      ::ng-deep .mdc-switch__handle::after {
        background: #7E7E7E;
      }

      &.mat-mdc-slide-toggle-checked {
        border: 2px solid #003B96;

        .mdc-switch__handle::after {
          background: #003B96;
        }
      }

      ::ng-deep .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
        background: #003B96;
      }

      &.error {
        &.mat-mdc-slide-toggle-checked {
          border: 2px solid #CB0000;

          div.mdc-switch__handle-track div.mdc-switch__handle::after {
            background: #CB0000;
          }
        }

        .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
          background: #CB0000;
        }

        .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
          background: #CB0000;
        }
      }
    }

  }

  #status-chip {
    &.status {
      background-color: #D3D3D380;

      .mdc-evolution-chip__text-label {
        color: #000;
      }
    }

    &.status-success {
      background-color: #00B05080;

      .mdc-evolution-chip__text-label {
        color: #000;
      }
    }

    &.status-info {
      background-color: #FF860080;

      .mdc-evolution-chip__text-label {
        color: #000;
      }
    }

    &.status-warn {
      background-color: #FF000080;

      .mdc-evolution-chip__text-label {
        color: #000;
      }
    }

    &.status-error {
      background-color: #BC243C80;

      .mdc-evolution-chip__text-label {
        color: #000;
      }
    }
  }

  #task {
    aside {
      background-color: #FAFAFA;
      border: 1px solid #646464;
    }

    .sidebar-item {
      background-color: #FAFAFA;

      &.disabled {
        background-color: #C4C4C4;
      }
    }

    main {
      background-color: #FAFAFA;
      border: 1px solid #646464;
    }
  }

  #table-footer {
    .page-size-select {
      .mat-mdc-select {
        border: 1px solid #979494;
      }
    }
  }

  #long-data-column {
    .long-data-column-copy-icon {
      color: #66A2FF;
    }
  }

  #nav-menu-second-layer {
    .nav-menu-button-link-primary-color {
      background-color: #1A2373;

      a {
        color: #fff;
      }
    }
  }
}

.dark-theme {
}

@media screen and (max-width: 1600px) {

  body {
    font-size: .75rem;
  }

  #header {

    .header-container {
      height: 35px;
      flex-basis: 35px;
    }

    .mat-toolbar-single-row {
      height: 35px;

      span {
        font-size: .75rem;
      }

      .nav-block {

        span {
          font-size: .55rem;
        }

        .search-box {
          transform: scale(0.55);
        }
      }
    }

    .mat-mdc-chip {
      height: 20px;

      .mat-mdc-chip-graphic {
        transform: translateY(3px);
      }

      .mdc-evolution-chip__text-label {
        font-size: .6rem;
      }
    }
  }

  #component-header {
    height: 35px;

    .subtitle {
      i {
        font-size: 1rem;
      }

      .mat-headline-6 {
        font-size: .75rem;
      }
    }

    .quilliup-link {
      font-size: .5rem;
    }

    .secondary-button {
      font-size: .5rem;
      line-height: 22px;
      height: 22px;
      padding: 0 20px;
    }
  }

  .home-info, .signin {
    h1 {
      font-size: 1.2rem;
    }

    p {
      font-size: .8rem;
    }

    button {
      zoom: 70%;
    }
  }

  #kpi-sidebar {
    max-width: 4rem;

    .icons-container {
      i {
        font-size: 1rem;
      }
    }

    .sidebar-item {
      width: 4rem;

      &-value {
        font-size: 1.7rem;
      }

      &-title {
        font-size: .6rem;
      }
    }
  }

  .ag-theme-quartz {
    --ag-grid-size: 4px;
    --ag-row-height: 30px;

    .ag-root-wrapper-body {
      font-size: .6rem;
    }

    .ag-header-row {
      height: 25px;
    }

    div.multi-line-link-column {
      line-height: 8px;

      div {
        margin: 0;
      }
    }
  }

  .ag-tool-panel-wrapper {
    zoom: 90%;
    width: 150px;
  }

  .ag-popup-child {
    transform: scale(.7);
  }

  #status-chip {
    height: 22px !important;

    .mat-mdc-chip-action-label {
      font-size: .6rem;
    }
  }

  #action-column {
    a {
      font-size: .85rem;
      width: 20px;
      height: 20px;
    }

    .mdc-button__label {
      transform: translateX(-5px);
    }
  }

  #long-data-column {
    font-size: .6rem;

    .long-data-column-text {
      font-size: .6rem;
    }
  }

  .multi-line-table-link {
    font-size: .6rem;
  }

  #clear-filters-button {
    height: 20px;
    font-size: .6rem;
  }

  #table-footer {

    .action-section {
      button {
        font-size: .5rem;
        line-height: 25px;
        height: 25px;
        padding: 0 15px;
      }
    }

    .page-size-select {
      label {
        font-size: .6rem;
        width: 70px;
      }

      .mat-mdc-select {
        transform: scale(.7);
      }
    }

    .pagination-section {
      zoom: 60%;
    }
  }

  #auto-refresh-wrapper {
    transform: scale(0.7);
  }

  .context-link {
    margin: 10px;

    a {
      zoom: 60%;
    }
  }

  .transfer-manual-run {
    max-width: 70%;

    .mat-mdc-text-field-wrapper {
      height: 40px;
    }

    .mdc-floating-label {
      top: 18px !important;
      font-size: .6rem;
    }

    .mat-mdc-select-value-text {
      font-size: .7rem;
    }

    .mat-mdc-form-field-infix {
      padding-top: 1rem !important;
    }

    .expansion-panel-block {
      zoom: 80%;
    }

    .actions-section {
      zoom: 70%;
    }
  }

  #datablock {

    .title {
      font-size: .45rem;
    }

    .data {
      font-size: .6rem;
    }
  }

  #slide-toggle-wrapper {
    transform: scale(0.7);
  }

  #task {
    .go-back-block {
      zoom: 60%;
    }

    .sidebar-and-main {
      aside {
        min-width: 5rem;
        width: 5rem;

        .sidebar-item {
          height: 5rem;

          i {
            font-size: .75rem;
          }

          span {
            font-size: .6rem;
          }
        }
      }
    }
  }

  // applies to every id, that ends with '-detail' or '-bulk'
  [id$="-detail"]:is([id$="-detail"]),
  [id$="-bulk"]:is([id$="-bulk"]) {

    .mat-mdc-card-header {
      padding: 8px 8px 0;
      margin-bottom: 10px;
    }

    .mat-mdc-card-content {
      padding: 0 8px 8px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 8px;
    }

    .header-title {
      .mat-mdc-card-title {
        font-size: 1rem;
      }

      .mat-mdc-card-subtitle {
        font-size: .6rem;
      }
    }

    .url-container {
      label.url-label {
        font-size: .5rem;
      }

      a.url-link {
        font-size: .55rem;
      }
    }

    .invalid-toggle {
      margin-right: 0;

      span {
        margin-left: 0;
      }
    }

    .mat-mdc-text-field-wrapper {
      height: 45px;
    }

    .mdc-floating-label {
      top: 18px !important;
      font-size: .6rem;
    }

    .mat-mdc-select-value-text {
      font-size: .7rem;
    }

    .mat-mdc-input-element {
      font-size: .7rem;
    }

    .mat-mdc-form-field-infix {
      padding-top: 1rem !important;
    }

    .status-container {
      zoom: 70%;
    }

    .action-buttons {
      zoom: 70%;
    }
  }

  #file-processing {
    .flex-g-m {
      gap: 10px;
    }

    .page-sub-header {
      font-size: 1rem;
      margin: 8px;
    }

    .action-buttons {
      zoom: 70%;
    }
  }

  #file-upload {
    zoom: 80%;
  }

  .mat-datepicker-toggle {
    zoom: 60%;
    transform: translateY(-10px);
  }

  .mat-drawer-inner-container {
    zoom: 80%;
  }

  .footer-container {
    flex-basis: 28px;

    .footer {
      height: 28px !important;
    }
  }


  .cdk-overlay-pane {

    .mat-mdc-menu-item {
      min-height: 25px;
    }

    .mdc-list-item__primary-text {
      font-size: .6rem !important;
    }

    .mat-mdc-option {
      min-height: 25px;

      .mdc-list-item__primary-text {
        font-size: .5rem !important;
      }
    }

    .mat-mdc-optgroup-label {
      min-height: auto;
    }

    .mdc-tooltip__surface {
      zoom: 70%;
    }

    .mat-calendar {
      zoom: 70%;
    }

    .confirmation-dialog {
      zoom: 60%;
    }

    .mdc-snackbar {
      zoom: 80%;
    }

    .mat-datepicker-content-container {
      zoom: 90%;

      .time-container {
        zoom: 80%;
      }

      .mat-datepicker-actions {
        zoom: 80%;
      }
    }
  }
}

.grid-cell-wrapper {
  line-height: 1.4;
  padding: 4px;
}

.grid-cell-right-align {
  text-align: right;
}

.ag-selection-checkbox {
  margin-left: var(--ag-cell-widget-spacing)
}

//hides the number of the sort order in ag-grid header cell
.ag-header-cell .ag-sort-order {
  display: none
}

.ag-grid-flex {
  flex: 1;
}

.mat-mdc-menu-panel {
  padding: 0;
  max-width: fit-content !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.available {
  color: green;
}

.failed {
  color: red;
}
